<script setup>
import { computed, ref, onMounted } from "vue";
import { useClientCenterStore } from "@/store/clientCenterStore";
import { storeToRefs } from "pinia";
import TagSet from "./components/TagSet.vue";
import UserSet from "./components/UserSet.vue";
import RecordView from "./components/RecordView.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  currentTab: String, // 接收當前選中的 tab
});

const clientCenterStore = useClientCenterStore();
const { selectedUser, tags, filterTags, filterUsers, userLists } = storeToRefs(clientCenterStore);
const tagArea = ref(null);

const filterTagUser = computed(() => {
  return userLists.value.filter(user => {
    if (filterTags.value.length === 0 && filterUsers.value === "") {
      return true;
    }
    const result = ref(false);
    if (filterTags.value.includes("")) {
      result.value = filterTags.value.some(tag => user.tags.length === 0 || user.tags?.includes(tag) || false);
    } else {
      result.value = filterTags.value.some(tag => user.tags?.includes(tag) || false);
    }
    if (filterUsers.value === "") {
      return result.value;
    } else {
      return result.value || user.name.includes(filterUsers.value);
    }
  });
});

onMounted(() => {
  clientCenterStore.getUserList();
});
</script>
<template>
  <div class="row">
    <div class="clientCenter border pt-2 d-flex flex-column gap-2" :class="$store.getters.mobileSize ? 'col-12' : 'col-4'" v-if="$store.getters.mobileSize ? selectedUser === '' : true">
      <div class="d-flex gap-2">
        <el-input v-model="filterUsers" placeholder="查詢使用者"></el-input>
        <el-popover>
          <template #reference>
            <el-button type="text"><i class="fa-solid fa-filter"></i></el-button>
          </template>
          <el-checkbox-group v-model="filterTags" class="d-flex flex-column gap-2">
            <el-checkbox :key="NoChoice" label=""> 未選擇 </el-checkbox>
            <el-checkbox v-for="tag in tags" :key="tag" :label="tag">
              {{ tag }}
            </el-checkbox>
          </el-checkbox-group>
        </el-popover>
      </div>
      <ul class="list-group list-group-flush overflow-auto pe-2">
        <li v-for="(i, index) in filterTagUser" :key="index" class="list-group-item p-0">
          <div ref="tagArea" class="d-flex align-items-center px-2 w-100 overflow-hidden" style="height: 80px" :class="i.clicked ? 'selectedChat' : ''" @click="clientCenterStore.activeTagUser(i)">
            <el-avatar size="medium" :src="i.memberAvatar == '' ? 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' : i.memberAvatar" class="flex-shrink-0"></el-avatar>
            <div class="chatInfo ms-2 d-flex flex-column align-items-start">
              <div class="mb-1 userName mb-2">
                {{ i.nickName == "" ? (i.name == "" ? "匿名者" : i.name) : i.nickName }}
              </div>
              <div class="d-flex gap-1 flex-shrink-0">
                <el-tag v-for="tag in i.tags" :key="tag">
                  {{ tag }}
                </el-tag>
              </div>
              <div v-if="isOverflow" class="text-danger" style="font-size: 12px">...</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="selectedUser !== ''" :class="$store.getters.mobileSize ? 'col-12' : 'col-7 ms-2'" class="clientCenter border d-flex flex-column flex-grow-1">
      <UserSet v-if="props.currentTab === 'userSet'" @rerenderMember="clientCenterStore.getUserList()" />
      <TagSet v-else-if="props.currentTab === 'tagSet'" />
      <RecordView v-else-if="props.currentTab === 'recordView'" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.clientCenter {
  height: 80vh !important;
  overflow: auto;
}

.userInfo {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.selectedChat {
  background-color: #3373f390;
}

.tagDivide {
  margin: 5px 0px !important;
}

.chatInfo {
  padding: 1rem;
  .userName {
    font-size: 14px;
  }
  .lastMessage {
    font-size: 12px;
  }
}

.chatPopup {
  background-color: #f2f3f5;
  padding: 5px;
  border-radius: 8px;
  white-space: pre-wrap;
}

.chatPopup-send {
  background-color: #d1dcf5;
  padding: 5px;
  border-radius: 8px;
  white-space: pre-wrap !important;
  text-align: start;
}

.chat-createdDateTime {
  font-size: xx-small;
  text-align: start;
  margin-bottom: 6px;
}

.messageArea {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  /* background-color: aqua; */

  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
}

.chatArea {
  height: 70vh;
  overflow-y: auto;
}

.mobileMode {
  position: fixed !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: 140px;
}
@media screen and (min-width: 768px) {
  .clientCenter {
    height: 75vh;
    overflow: auto;
  }
}
</style>
