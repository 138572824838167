<template>
  <UserActiveComponent />
  <el-divider class="tagDivide" />
  <div>
    <el-form @submit.prevent :model="userSettings" :rules="rules" ref="userSettings" label-position="top">
      <div class="name">
        <el-form-item prop="name" :label="$t('QACenter.UserSet.NickName')">
          <el-input @click="changeToEdit(1, true)" ref="autoFocus1_user" v-model="userSettings.name" @keydown.enter.prevent="userEditCompleted(1)" :placeholder="$t('QACenter.UserSet.InputNickName')" :readonly="!isUserSettingsClickedArray[1]" :class="{ disActive: !isUserSettingsClickedArray[1] }" />
          <div class="edit_group">
            <EditInputButton @click="changeToEdit(1)" v-if="!isUserSettingsClickedArray[1]" />
            <EditButtonGroup class="editButtonGroup" v-else @cancel="changeToOrigin(1)" @confirm="userEditCompleted(1)" />
          </div>
        </el-form-item>
      </div>
      <div class="email">
        <el-form-item prop="email" :label="$t('QACenter.UserSet.Email')">
          <el-input @click="changeToEdit(2, true)" ref="autoFocus2_user" v-model="userSettings.email" @keydown.enter.prevent="userEditCompleted(2)" :placeholder="$t('QACenter.UserSet.InputEmail')" :readonly="!isUserSettingsClickedArray[2]" :class="{ disActive: !isUserSettingsClickedArray[2] }" />
          <div class="edit_group">
            <EditInputButton @click="changeToEdit(2)" v-if="!isUserSettingsClickedArray[2]" />
            <EditButtonGroup class="editButtonGroup" v-else @cancel="changeToOrigin(2)" @confirm="userEditCompleted(2)" />
          </div>
        </el-form-item>
      </div>
      <div class="address">
        <el-form-item prop="address" :label="$t('QACenter.UserSet.Address')">
          <el-input @click="changeToEdit(3, true)" ref="autoFocus3_user" v-model="userSettings.address" @keydown.enter.prevent="userEditCompleted(3)" :placeholder="$t('QACenter.UserSet.InputAddress')" :readonly="!isUserSettingsClickedArray[3]" :class="{ disActive: !isUserSettingsClickedArray[3] }" />
          <div class="edit_group">
            <EditInputButton @click="changeToEdit(3)" v-if="!isUserSettingsClickedArray[3]" />
            <EditButtonGroup class="editButtonGroup" v-else @cancel="changeToOrigin(3)" @confirm="userEditCompleted(3)" />
          </div>
        </el-form-item>
      </div>
      <div class="cellPhone">
        <el-form-item prop="cellPhone" :label="$t('QACenter.UserSet.CellPhone')">
          <el-input @click="changeToEdit(4, true)" ref="autoFocus4_user" v-model="userSettings.cellPhone" @keydown.enter.prevent="userEditCompleted(4)" :placeholder="$t('QACenter.UserSet.InputCellPhone')" :readonly="!isUserSettingsClickedArray[4]" :class="{ disActive: !isUserSettingsClickedArray[4] }" />
          <div class="edit_group">
            <EditInputButton @click="changeToEdit(4)" v-if="!isUserSettingsClickedArray[4]" />
            <EditButtonGroup class="editButtonGroup" v-else @cancel="changeToOrigin(4)" @confirm="userEditCompleted(4)" />
          </div>
        </el-form-item>
      </div>
      <div class="birthday">
        <el-form-item prop="birthday" :label="$t('QACenter.UserSet.Birthday')">
          <div>
            <el-date-picker ref="autoFocus5_user" v-if="isUserSettingsClickedArray[5]" v-model="userSettings.birthday" type="date" value-format="YYYY-MM-DD" :placeholder="$t('QACenter.UserSet.InputBirthday')" :readonly="!isUserSettingsClickedArray[5]" :class="{ active: !isUserSettingsClickedArray[5] }"></el-date-picker>
            <div class="birthdayText" @click="changeToEdit(5)" v-else>
              <div v-if="birthday != ''"><i class="fa-light fa-calendar"></i>{{ birthday }}</div>
              <div v-else>
                <i class="fa-light fa-calendar"></i><span style="color: #a1a5ad">{{ $t("QACenter.UserSet.InputBirthday") }}</span>
              </div>
            </div>
          </div>
          <div class="edit_group d-flex justify-content-end">
            <EditInputButton @click="changeToEdit(5, true)" v-if="!isUserSettingsClickedArray[5]" />
            <EditButtonGroup v-else @cancel="changeToOrigin(5)" @confirm="userEditCompleted(5)" />
          </div>
        </el-form-item>
      </div>
      <div class="remark">
        <el-form-item prop="remark" :label="$t('QACenter.UserSet.Remark')">
          <el-input style="resize: none" type="textarea" rows="10" @click="changeToEdit(6, true)" ref="autoFocus6_user" v-model="userSettings.remark" :placeholder="$t('QACenter.UserSet.InputRemark')" :readonly="!isUserSettingsClickedArray[6]" :class="{ disActive: !isUserSettingsClickedArray[6] }" />
          <div class="edit_group">
            <EditInputButton @click="changeToEdit(6)" v-if="!isUserSettingsClickedArray[6]" />
            <EditButtonGroup class="editButtonGroup" v-else @cancel="changeToOrigin(6)" @confirm="userEditCompleted(6)" />
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import { useClientCenterStore } from "@/store/clientCenterStore";
import UserActiveComponent from "../components/ClientCenter/UserActiveComponent.vue";
import EditInputButton from "../../../Common/CommonElement/EditInputButton.vue";
import EditButtonGroup from "../../../Common/EditButtonGroup.vue";
const dayjs = require("dayjs");

export default {
  name: "UserSet",
  components: {
    UserActiveComponent,
    EditInputButton,
    EditButtonGroup,
  },
  data() {
    return {
      store: useClientCenterStore(),
      userSettings: {
        name: "",
        email: "",
        address: "",
        cellPhone: "",
        birthday: "",
        remark: "",
      },
      userSettingsBackup: {},
      isUserSettingsClickedArray: [false, false, false, false, false, false, false],
      rules: {
        email: [
          {
            type: "email",
            message: `"請輸入正確信箱格式"`,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    // Channel & User settings
    async getUserSettings() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/enterprise/IndividualUser/${vm.store.selectedUser.id}`)
        .then(res => {
          vm.userSettings = {
            name: res.data.name,
            email: res.data.email,
            address: res.data.address,
            cellPhone: res.data.cellPhone,
            birthday: vm.turnBirthdayType(res.data.birthday),
            remark: res.data.remark,
          };
        })
        .catch(err => {
          console.log(err);
        });
    },
    userEditCompleted(index) {
      const vm = this;
      vm.$refs["userSettings"].validate(valid => {
        if (valid) {
          let data = {
            userId: vm.store.selectedUser.id,
            name: vm.userSettings.name,
            email: vm.userSettings.email,
            address: vm.userSettings.address,
            cellPhone: vm.userSettings.cellPhone,
            birthday: vm.userSettings.birthday,
            remark: vm.userSettings.remark,
          };
          vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/enterprise/IndividualUser`, data)
            .then(() => {
              vm.userSettingsBackup = _.cloneDeep(vm.userSettings);
              vm.$emit("rerenderMember");
              vm.store.selectedUser.nickName = data.name;
              vm.notify("success", "資料更新成功！");
            })
            .catch(err => {
              vm.$refs["userSettings"].resetFields();
              vm.userSettings = _.cloneDeep(vm.userSettingsBackup);
              vm.notify("error", `資料更新失敗，${err.response.data.Msg}！`);
            })
            .finally(() => {
              vm.isUserSettingsClickedArray[index] = false;
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
    changeToEdit(index, needCheck) {
      if (!this.isUserSettingsClickedArray.every(item => item === false)) {
        if (needCheck && this.isUserSettingsClickedArray[index]) {
          return;
        } else {
          this.userSettings = _.cloneDeep(this.userSettingsBackup);
        }
      }
      this.userSettingsBackup = _.cloneDeep(this.userSettings);
      this.isUserSettingsClickedArray = [false, false, false, false, false, false, false];
      this.isUserSettingsClickedArray[index] = true;
      this.$refs[`autoFocus${index}_user`].focus();
    },
    changeToOrigin(index) {
      this.$refs["userSettings"].resetFields();
      this.userSettings = _.cloneDeep(this.userSettingsBackup);
      this.isUserSettingsClickedArray[index] = false;
    },
  },
  watch: {
    "store.selectedUser.id"() {
      this.getUserSettings();
    },
  },
  computed: {
    birthday() {
      const date = dayjs(this.userSettings.birthday);
      return date.isValid() ? date.format("YYYY-MM-DD") : "";
    },
  },
  async created() {
    await this.getUserSettings();
  },
};
</script>

<style lang="scss" scoped>
:deep .el-form-item {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(183, 183, 183);
}
:deep .remark .el-form-item:last-child {
  border-bottom: none;
}
.birthdayText {
  width: 8rem;
  font-size: 1rem;
  color: #606266;
  i {
    font-size: 0.8rem;
    margin-right: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  :deep .remark .el-form-item {
    .el-form-item__content {
      flex-direction: column !important;
      .edit_group {
        margin-top: 1rem;
      }
    }
  }
}
</style>
