<script setup>
import { computed, ref } from 'vue'
import { useClientCenterStore } from '@/store/clientCenterStore';
import { storeToRefs } from 'pinia';
import UserActiveComponent from "../components/ClientCenter/UserActiveComponent.vue";

const clientCenterStore = useClientCenterStore()
const { selectedUser, tags, tempTagName } = storeToRefs(clientCenterStore)
const filterTagName = ref('')
const tagNameItem = computed(() => {
    return tags.value.filter(tag => tag.includes(filterTagName.value) || tag === '')
})
const addTag = () => {
    tags.value.push('')
}
const removeTag = () => {
    tags.value.pop()
}
const saveTag = (index) => {
    if (tempTagName.value === '') {
        return
    }
    tags.value[index] = tempTagName.value
    tempTagName.value = ''
}
</script>
<template>
    <UserActiveComponent/>
    <el-divider class="tagDivide" />
    <div class="d-flex justify-content-between align-items-center px-2">
        <span>標籤選擇</span>
        <el-input v-model="filterTagName"
                  style="width: 30%;"
                  placeholder="查詢標籤"></el-input>
    </div>
    <div class="d-flex flex-wrap gap-2 p-2">
        <el-checkbox-group v-model="selectedUser.tags"
                           class="d-flex flex-wrap gap-2">
            <el-checkbox v-for="(tag, index) in tagNameItem"
                         :key="tag"
                         :disabled="tag === ''"
                         :label="tag">
                <template #default>
                    <span v-if="tag !== ''">{{ tag }}</span>
                    <input v-else
                           v-model="tempTagName"
                           type="text"
                           placeholder="新增標籤"
                           @keydown.enter="saveTag(index)"
                           @keydown.esc="removeTag" />
                </template>
            </el-checkbox>
            <el-button link
                       v-if="tags[tags.length - 1] !== ''"
                       @click="addTag">
                <i class="fa-solid fa-plus" />
            </el-button>
            <el-button link
                       v-if="tags[tags.length - 1] === ''"
                       @click="saveTag(tags.length - 1)"><i class="fa-solid fa-check text-success"></i></el-button>
            <el-button link
                       v-if="tags[tags.length - 1] === ''"
                       @click="removeTag"><i class="fa-solid fa-minus "></i></el-button>
        </el-checkbox-group>

    </div>
</template>