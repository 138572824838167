<template>
  <div class="qaCenter">
    <el-tabs v-model="activeTab" :tab-position="$store.getters.mobileSize ? 'top' : 'left'" class="mt-3" stretch="true">
      <el-tab-pane :label="$t('QACenter.UserSetTitle')" name="userSet">
        <div class="content">
          <ClientCenter v-if="activeTab === 'userSet'" :currentTab="activeTab" />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('QACenter.TagSetTitle')" name="tagSet">
        <div class="content">
          <ClientCenter v-if="activeTab === 'tagSet'" :currentTab="activeTab" />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('QACenter.RecordViewTitle')" name="recordView">
        <div class="content">
          <ClientCenter v-if="activeTab === 'recordView'" :currentTab="activeTab" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ClientCenter from "../QACenter/ClientCenter.vue";

export default {
  name: "QACenter",
  components: {
    ClientCenter,
  },
  data() {
    return {
      activeTab: "userSet",
    };
  },
};
</script>

<style lang="scss" scoped>
.qaCenter {
  padding: 1rem 2rem 0;
  ::v-deep .el-tabs {
    height: 100%;
  }
  ::v-deep .el-tabs {
    height: 100%;
    .content {
      text-align: start;
      margin-top: 1rem;
      .dangerText {
        margin-bottom: 1rem;
        color: rgb(225, 121, 121);
        font-weight: bold;
        line-height: 1.5rem;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  ::v-deep .el-tabs {
    .el-tabs__content {
      padding: 0 2rem;
    }
    .content {
      margin-top: 0 !important;
    }
  }
}
</style>
