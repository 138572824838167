import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUtils } from '@/utils/index'
const utils = useUtils()
export const useClientCenterStore = defineStore('clientCenterStore', () => {
    const actionNum = ref(0)
    const selectedUser = ref('')
    const tempTagName = ref('')
    const tags = ref(['123', '456'])
    const filterTags = ref([])
    const filterUsers = ref('')
    const userLists = ref([])

    const activeTagUser = (user) => {
        if (selectedUser.value === user) {
            return
        }
        if (selectedUser.value !== '') {
            selectedUser.value.clicked = false
        }
        selectedUser.value = user
        selectedUser.value.clicked = true
    }
    const unActiveTagUser = () => {
        selectedUser.value.clicked = false
        selectedUser.value = ''
    }
    const getUserList = () => {
        utils.fetchAPI("GET", `${process.env.VUE_APP_URL_API}/LineBot/GetCamperAllUsers`)
            .then((res) => {
                console.log(res);
                userLists.value = res.data.map((user) => {
                    return {
                        ...user,
                        clicked: false
                    }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return {
        actionNum,
        selectedUser,
        tempTagName,
        tags,
        filterTags,
        filterUsers,
        userLists,
        activeTagUser,
        unActiveTagUser,
        getUserList
    }
})