<script setup>
import { useClientCenterStore } from "@/store/clientCenterStore";
import { storeToRefs } from "pinia";
const clientCenterStore = useClientCenterStore();
const { selectedUser } = storeToRefs(clientCenterStore);
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="px-2 pt-4 fs-3">
      <el-button type="text" v-if="$store.getters.mobileSize" @click="clientCenterStore.unActiveTagUser">
        <i class="fa-solid fa-times"></i>
      </el-button>
      <span  :class="$store.getters.mobileSize ? 'ms-3' : ''">
        <span>{{ selectedUser.nickName }}</span
        ><span v-if="selectedUser.nickName != selectedUser.name">({{ selectedUser.name }})</span>
      </span>
    </div>
  </div>
</template>
