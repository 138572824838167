<template>
  <UserActiveComponent />
  <el-divider class="tagDivide" />
  <div class="recordView mb-3">QA記錄檢視</div>
</template>

<script>
import UserActiveComponent from "../components/ClientCenter/UserActiveComponent.vue";

export default {
  name: "RecordView",
  components: {
    UserActiveComponent,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  computed: {},
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.recordView {
}
</style>
